/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import Modal from 'react-modal';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { isLoggedIn, isNpsEnabled } from '../../Utils/Utils';
import { Down, MenuIcon } from '../../static';

import { Button, Drawer, Dropdown, Menu } from 'antd';

import { useHistory } from 'react-router-dom';
import { API_URL } from '../../constant';
import ResetPassword from '../../containers/includes/ResetPassword';
import VerifyAccount from '../../containers/includes/VerifyAccount';
import LogInRegisterModal from '../LogInRegisterModal/';
import ForgetPassword from '../LogInRegisterModal/ForgotPasswordModal';
import MenuMob from '../Menu';
import NotificationPage from '../NotificationPage';
import HeaderUser from './HeaderUser';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const lpkMenus = [
  {
    title: 'LPK - Pemagangan Jepang',
    link: 'https://lpk-gee.myedusolvex.com/',
    isHttps: true,
    disabled: false,
  },
  {
    title: 'LPK - Teknologi Informasi & Komunikasi (Coming Soon)',
    link: 'https://lpk-mes.myedusolvex.com/',
    isHttps: true,
    disabled: true,
  },
  {
    title: 'PastiMagang (Coming Soon)',
    link: 'https://pastimagang.myedusolvex.com/',
    isHttps: true,
    disabled: true,
  },
];

const menu = (
  <Menu className="menu-new-homepage">
    <Menu.ItemGroup>
      <h2 style={{ paddingBottom: '8px', borderBottom: '1px solid black' }}>LEARN</h2>
      <Menu.Item key={1}>
        <a href="/courses?page=1&category=2">Tech</a>
      </Menu.Item>
      <Menu.Item key={2}>
        <a href="/courses?page=1&category=4">Business</a>
      </Menu.Item>
      <Menu.Item key={3}>
        <a href="/courses?page=1&category=5">Entrepreneurship</a>
      </Menu.Item>
      <Menu.Item key={4}>
        <a href="/courses?page=1&category=1">Self Development</a>
      </Menu.Item>
      <Menu.Item key={5}>
        <a href="/courses?page=1&category=3">Creative</a>
      </Menu.Item>
      <Menu.Item key={6}>
        <a href="/prakerja">Prakerja</a>
      </Menu.Item>

      {/* <h2>OPPORTUNITIES</h2>
      <Link to="/courses?page=1">Kormo Jobs</Link> */}
    </Menu.ItemGroup>
    <Menu.ItemGroup>
      <h2 style={{ paddingBottom: '8px', borderBottom: '1px solid black' }}>MEET</h2>
      {/* <Link to="/courses?page=1">Community</Link> */}
      <Menu.Item key={7}>
        <Link to="/intern">Internship</Link>
      </Menu.Item>
      {/* <Link to="/courses?page=1">Career</Link> */}
      {/* <Link to="/courses?page=1">Collaborate</Link> */}
    </Menu.ItemGroup>

    {/* LPK DESKTOP */}
    <Menu.ItemGroup style={{ maxWidth: '250px' }}>
      <h2 style={{ paddingBottom: '8px', borderBottom: '1px solid black' }}>LPK</h2>

      {lpkMenus.map((el, i) => {
        return (
          <Menu.Item>
            <a
              target="_blank"
              rel={'noreferrer noopener'}
              href={el.link}
              style={{
                pointerEvents: el.disabled ? 'none' : 'auto',
                cursor: el.disabled ? 'not-allowed' : 'pointer',
                opacity: el.disabled ? '0.5' : 1,
              }}
            >
              {el.title}
            </a>
          </Menu.Item>
        );
      })}
    </Menu.ItemGroup>
    {/* LPK DESKTOP */}

    <Menu.ItemGroup>
      <Menu.Item key={8}>
        <Link to="/" className="menu-new-bold">
          Home
        </Link>
      </Menu.Item>
      <Menu.Item key={9}>
        <a href="https://myedusolve.com/about" className="menu-new-bold">
          About Us
        </a>
      </Menu.Item>
      <Menu.Item key={10}>
        <Link to="/courses" className="menu-new-bold">
          All Courses
        </Link>
      </Menu.Item>
      <Menu.Item key={11}>
        <Link to="/webinar" className="menu-new-bold">
          Our Events
        </Link>
      </Menu.Item>
      <Menu.Item key={12}>
        <Link to="/instructors" className="menu-new-bold">
          Instruktur di MyEduSolveX
        </Link>
      </Menu.Item>
    </Menu.ItemGroup>
  </Menu>
);

const GlassIcon = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`ml-auto ${isMobile ? '' : 'mr-3'}`}
    >
      <path
        d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z"
        fill="black"
      />
    </svg>
  );
};

const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill="black"
    />
  </svg>
);

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();
  const profile_data = useSelector((state) => state.profile_data);
  const searchBar = useRef(null);
  const [searchBarWidth, setSearchBarWidth] = useState('unset');
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const [visible, setVisible] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const [isResetMenuOpen, setIsResetMenuOpen] = useState(false);
  const [isVerifyMenuOpen, setIsVerifyMenuOpen] = useState(false);
  const [showModalJournal, setShowModalJournal] = useState(false);
  const [showModalTugasMandiri, setShowModalTugasMandiri] = useState(false);
  const [showModalUK, setShowModalUK] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const customStyles = {
    content: {
      top: '262px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100vw',
      padding: '8px',
      borderRadius: 0,
      height: '420px',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.55)',
      zIndex: 1020,
    },
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const checkLogin = async () => {
      dispatch({ type: 'TOGGLE_LOADING_TRUE' });
      const user_check_api = await isLoggedIn();
      dispatch({
        type: 'ADD_PROFILEDATA',
        payload: user_check_api.profile_data,
      });
      if (user_check_api.profile_data !== null) {
        isNpsEnabled()
          .then((success) => {
            dispatch({
              type: 'ADD_NPS_ELIGIBLE',
              payload: success,
            });
          })
          .catch((error) => {
            return error;
          });
      }
      dispatch({ type: 'TOGGLE_LOADING_FALSE' });
    };
    let reset_token = query.get('reset_token');
    if (reset_token != null && location.pathname === '/reset') {
      setIsResetMenuOpen(true);
      setResetToken(reset_token);
    } else if (reset_token != null && location.pathname === '/verify') {
      setIsVerifyMenuOpen(true);
      setResetToken(reset_token);
    }
    checkLogin();
  }, []);

  const searchBarSearch = () => {
    setSearchLoading(true);
    axios
      .get(
        `${window.$API_URL}api/common/searchcoursewithcategory/?q=${search}`,
        {},
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then((success) => {
        setSearchResult(success.data.data);
        setSearchLoading(false);
      })
      .catch((error) => {
        NotificationManager.error('Some Error Occured in API!');
        setSearchLoading(false);
      });
  };

  useEffect(() => {
    if (searchBar.current) {
      setSearchBarWidth(searchBar.current.offsetWidth);
    }
  }, [searchBar.current]);

  useEffect(() => {
    if (search) {
      let debouncer = setTimeout(() => {
        setSearchBarOpen(true);
        searchBarSearch();
      }, 800);
      return () => {
        clearTimeout(debouncer);
      };
    } else {
      setSearchBarOpen(false);
    }
  }, [search]);

  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearchBarOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  function useInsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && ref.current.contains(event.target) && search) {
          setSearchBarOpen(true);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, search]);
  }
  useInsideClick(searchBar);
  useOutsideClick(searchBar);

  useEffect(() => {
    const fetchJournalCheck = async () => {
      const token = localStorage.getItem('access_token');

      try {
        let res = await axios.get(`${API_URL}/kjflow/journalcheck`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        res = res?.data?.data;

        // if user havent submitted and not in journal page
        if (res && !location.pathname.includes('/student/reflective-journal')) {
          setShowModalJournal(true);
        }
      } catch (error) {
        return error;
      }
    };

    fetchJournalCheck();

    const fetchTugasMandiriCheck = async () => {
      const token = localStorage.getItem('access_token');

      try {
        let res = await axios.get(`${API_URL}/kjflow/tugasmandiripending`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        res = res?.data?.data;

        // if user havent submitted and not in journal page
        if (res && !location.pathname.includes('/student/tugas-mandiri')) {
          setShowModalTugasMandiri(true);
        }
      } catch (error) {
        return error;
      }
    };

    fetchTugasMandiriCheck();

    const fetchUKCheck = async () => {
      const token = localStorage.getItem('access_token');

      try {
        let res = await axios.get(`${API_URL}/kjflow/uktestpending`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        res = res?.data?.data;

        // if user havent submitted and not in journal page
        if (res && !location.pathname.includes('/student/unjuk-keterampilan')) {
          setShowModalUK(true);
        }
      } catch (error) {
        return error;
      }
    };

    fetchUKCheck();
  }, []);

  const goToJournalPage = () => {
    history.push('/student/reflective-journal');
  };

  const goToTugasMandiriPage = () => {
    history.push('/student/tugas-mandiri');
  };

  const goToUnjukKeterampilanPage = () => {
    history.push('/student/unjuk-keterampilan');
  };

  return (
    <>
      <div className="header_offset"></div>
      <header className="headercustomcss" style={{ zIndex: '1049' }}>
        <div className="headercustomcss__container container">
          <div className="row align-items-center">
            <div className="navbar-mob">
              <Button type="text" onClick={showDrawer} className="ml-2">
                <MenuIcon />
              </Button>
            </div>
            <div className="headercustomcss__logo-new">
              <Link to="/">
                <a>
                  <MediaQuery maxDeviceWidth={480}>
                    <img
                      src="/images/header-mob2.png"
                      alt="MyEduSolveX"
                      style={{ width: '139px', height: '20px' }}
                    />
                  </MediaQuery>
                </a>
                <a>
                  <MediaQuery minDeviceWidth={481}>
                    <img src="/images/header-small.png" alt="MyEduSolveX" />
                  </MediaQuery>
                </a>
              </Link>
            </div>
            <div className="headercustomcss__menu-button header-notification-button">
              {profile_data === null ? '' : <NotificationPage />}
            </div>
            <div className="headercustomcss__menu-button">
              <Button type="text" onClick={(e) => setModalOpen(true)} className="mr-4 mb-1">
                <GlassIcon />
              </Button>
            </div>
            <div className="headercustomcss__link-item-new">
              <Dropdown overlay={menu} placement="bottomLeft" overlayStyle={{ position: 'fixed' }}>
                <a className="headercustomcss__link-new" onClick={(e) => e.preventDefault()}>
                  Menu
                  <div className="headercustomcss__link-arrow">
                    <Down />
                  </div>
                </a>
              </Dropdown>
              <div className="headercustomcss__form" ref={searchBar}>
                <div>
                  <GlassIcon />
                  <input
                    placeholder="Cari kelas atau kategori"
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div
                  className={searchBarOpen ? 'search-bar-new-open' : 'search-bar-new-close'}
                  style={{ width: searchBarWidth }}
                >
                  {searchLoading ? (
                    <h2>Sedang mencari..</h2>
                  ) : searchResult[0] ? (
                    searchResult.map((el, i) =>
                      i < 10 ? (
                        <a href={`/course/${el.id}/${el.slug}?from=searchbar`}>
                          <div>{el.thumbnail ? <img src={el.thumbnail.url} alt="" /> : ''}</div>
                          <div>
                            <h4>{el.category ? el.category.category_name : ''}</h4>
                            <h2>{el.name}</h2>
                            <h3>{el.instructor ? el.instructor.fullname : ''}</h3>
                          </div>
                        </a>
                      ) : (
                        ''
                      ),
                    )
                  ) : (
                    <h2>Kelas atau Kategori tidak ditemukan!</h2>
                  )}
                  {}
                </div>
              </div>
            </div>
            <div className="headercustomcss__account col-auto">
              <HeaderUser onClose={onClose} />
            </div>
            <Drawer placement="left" closable={false} visible={visible} width={'100%'}>
              <MenuMob onClose={onClose} />
            </Drawer>
          </div>
        </div>
        <LogInRegisterModal />
        <ForgetPassword />
        <VerifyAccount isOpen={isVerifyMenuOpen} reset_token={resetToken} />
        <ResetPassword isOpen={isResetMenuOpen} reset_token={resetToken} />
        {/* <VoucherPopup /> */}
        <Modal
          closeTimeoutMS={100}
          isOpen={modalOpen}
          // onRequestClose={closeModal}
          style={customStyles}
          // contentLabel="Example Modal"
        >
          <div className="">
            <div className="headercustomcss__form" ref={searchBar}>
              <div>
                <input
                  placeholder="Cari kelas atau kategori"
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div onClick={() => setModalOpen(false)}>
                  <CloseIcon />
                </div>
              </div>
              <div
                className={searchBarOpen ? 'search-bar-new-open' : 'search-bar-new-close'}
                style={{ width: searchBarWidth }}
              >
                {searchLoading ? (
                  <h2>Sedang mencari..</h2>
                ) : searchResult[0] ? (
                  searchResult.map((el, i) =>
                    i < 10 ? (
                      <a href={`/course/${el.id}/${el.slug}?from=searchbar`}>
                        <div>{el.thumbnail ? <img src={el.thumbnail.url} alt="" /> : ''}</div>
                        <div>
                          <h4>{el.category ? el.category.category_name : ''}</h4>
                          <h2>{el.name}</h2>
                          <h3>{el.instructor ? el.instructor.fullname : ''}</h3>
                        </div>
                      </a>
                    ) : (
                      ''
                    ),
                  )
                ) : (
                  <h2>Kelas atau Kategori tidak ditemukan!</h2>
                )}
                {}
              </div>
            </div>
          </div>
        </Modal>

        {/* modal journal */}
        <Modal
          onRequestClose={() => setShowModalJournal(false)}
          closeTimeoutMS={100}
          isOpen={showModalJournal}
          overlayClassName="journal-popup-overlay"
          className="journal-popup-content"
        >
          <div className="">
            <p style={{ fontWeight: '600', fontSize: '18px', marginBottom: '1rem' }}>
              Silahkan mengisi reflective journal terlebih dahulu!
            </p>
            <button className="btn btn-pink w-100" onClick={() => goToJournalPage()}>
              Isi Jurnal
            </button>
          </div>
        </Modal>

        {/* modal tugas mandiri */}
        <Modal
          onRequestClose={() => setShowModalTugasMandiri(false)}
          closeTimeoutMS={100}
          isOpen={showModalTugasMandiri}
          overlayClassName="journal-popup-overlay"
          className="journal-popup-content"
        >
          <div className="">
            <p style={{ fontWeight: '600', fontSize: '18px', marginBottom: '1rem' }}>
              Silahkan mengumpulkan tugas mandiri terlebih dahulu!
            </p>
            <button className="btn btn-pink w-100" onClick={() => goToTugasMandiriPage()}>
              Kumpulkan tugas
            </button>
          </div>
        </Modal>

        {/* modal tugas mandiri */}
        <Modal
          onRequestClose={() => setShowModalUK(false)}
          closeTimeoutMS={100}
          isOpen={showModalUK}
          overlayClassName="journal-popup-overlay"
          className="journal-popup-content"
        >
          <div className="">
            <p style={{ fontWeight: '600', fontSize: '18px', marginBottom: '1rem' }}>
              Silahkan mengumpulkan tugas unjuk keterampilan terlebih dahulu!
            </p>
            <button className="btn btn-pink w-100" onClick={() => goToUnjukKeterampilanPage()}>
              Kumpulkan tugas
            </button>
          </div>
        </Modal>
      </header>
    </>
  );
};

export default Header;
